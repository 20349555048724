import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";
import { misc } from "@wunderflats/constants";
import classnames from "classnames";
import dayjs from "dayjs";
import omit from "lodash/omit";
import pick from "lodash/pick";
import qs from "qs";
import store from "store2";
import { INTERNAL_REFERRER_SESSION_STORAGE_KEY } from "../../../../../analytics/constants";
import BreadcrumbsNav from "../../../../../components/BreadcrumbsNav/BreadcrumbsNav";
import { Button } from "../../../../../components/DesignSystem/Button/Button";
import Footer from "../../../../../components/Footer/Footer";
import ListingsList from "../../../../../components/ListingsList/ListingsList";
import Pagination from "../../../../../components/Pagination/Pagination";
import RefugeeDistributionModal from "../../../../../components/RefugeeDistributionModal/RefugeeDistributionModal";
import { useFeatureFlags } from "../../../../../contexts/FeatureFlagContext";
import useMedia from "../../../../../hooks/useMedia";
import usePreviousValue from "../../../../../hooks/usePreviousValue";
import { listingsSearchResultClickPlacement } from "../../../../../tracker/constants";
import * as defaultListingFilter from "../../../../../utils/default-listing-filter";
import { canUseDOM } from "../../../../../utils/dom";
import { triggerGAEvent, updateQuery } from "../../../../../utils/filterUtils";
import {
  getBboxArrayFromString,
  getEncodedBboxStringFromLatLngArray,
  getGeocodingItemEnglishLabel,
  getGeocodingItemLabel,
} from "../../../../../utils/geocodingHelpers";
import getCofundedByTheEULogo from "../../../../../utils/get-cofunded-by-eu-logo";
import getHelfendeWaendeLogo from "../../../../../utils/get-helfende-waende-logo";
import { create as createListingViewModel } from "../../../../../utils/listing-view-model";
import { safeDecodeComponent } from "../../../../../utils/url";
import getWFCONFIG from "../../../../../utils/WF_CONFIG";
import { useAnalytics } from "../../../context/AnalyticsContext";
import { useApi } from "../../../context/ApiContext";
import { HoveredItemContextProvider } from "../../../context/HoveredItemContext";
import { useI18n } from "../../../context/I18nContext";
import { useTracker } from "../../../context/TrackerContext";
import PageHead from "../../../PageHead";
import CityDescription from "../CityDescription/CityDescription";
import DatesInfoBanner from "../DatesInfoBanner/DatesInfoBanner";
import MapErrorBoundary from "../ListingsMap/MapErrorBoundary";
import EmptyStateMessage from "./EmptyStateMessage";
import Header from "./Header";
import HeaderImage from "./HeaderImage";
import HeaderWithBanner from "./HeaderWithBanner";
import {
  DEFAULT_CITY,
  generateHeadContent,
  generateSrcSet,
  getHeadProps,
  getRandomKey,
  getRefugeeSearchLocationText,
  LISTINGS_PAGE_TABLET_BREAKPOINT,
  persistDataLayerVars,
  sanitizeRegions,
  shouldShowCityDescription,
  trackListingClickEvent,
} from "./utils";
import "../styles.scss";

const {
  FEATURE_FLAGS: {
    MAP_SEARCH_NO_AUTOMATIC_UPDATE,
    ENVIRONMENT_REFUGEE_DISTRIBUTION,
  },
  labels: { CATEGORY_HOMES_FOR_UKRAINIANS },
} = misc;

const HELFENDE_WAENDE_IMAGE =
  "https://images.prismic.io/wunderflatscontent/d94dc1cb-8e4f-44da-a5e1-9d4b1d911a8a_Refugee+Collection+Header+Mobile.png?auto=compress,format";

const ListingsMap = loadable(() => import("../ListingsMap/ListingsMap"), {
  ssr: false,
});

const WF_CONFIG = getWFCONFIG();

function ListingsPage({
  user,
  pageData: {
    dataLayerKey,
    content: pageDataContent,
    listingResults = {},
    structuredData: pageDataStructured,
    regions: pageDataRegions,
    defaultFilters,
    minBookingDuration,
    category,
    categories,
    searchBiasBbox,
    searchedPlaceBbox,
    regionDistricts,
    currentMunicipality,
    regionBbox,
  },
  params: {
    lang: propsParamsLang,
    region: propsParamsRegion,
    page: propsParamsPage,
  },
  query: propsQuery,
  currentUrl,
  translationUrls,
  url,
  history,
  isAPhone,
}) {
  const { isFeatureFlagEnabled } = useFeatureFlags();
  const { t, lang, keyExists } = useI18n();
  const api = useApi();
  const analytics = useAnalytics();
  const hasCategory = !!category;

  const routeName = hasCategory ? "categories" : "listinglist";

  /** isSmallScreen should be set to isAPhone for the first render (because of SSR).
   * But for all subsequent renders, it should use the media query to determine what to show.
   * That's why it's implemented as a state with an initial value of isAPhone that changes when the media query changes.
   */
  const [isSmallScreen, setIsSmallScreen] = useState(isAPhone);
  const isSmallScreenQuery = useMedia(
    `(max-width: ${LISTINGS_PAGE_TABLET_BREAKPOINT}px)`,
  );
  useEffect(() => {
    setIsSmallScreen(isSmallScreenQuery);
  }, [isSmallScreenQuery]);

  // Start Snowplow tracking related
  const { tracker } = useTracker();

  const [stateQuery, setStateQuery] = useState({});
  // End Snowplow tracking related

  // Start map related
  const [shouldReloadMap, setShouldReloadMap] = useState(true);
  const [listingsMapKey, setListingsMapKey] = useState(getRandomKey());

  const [mapMovingBecauseOfSearch, setMapMovingBecauseOfSearch] =
    useState(false);
  const [mapBounds, setMapBounds] = useState([]);

  const isMapView = propsQuery.view === "map";
  const isMobileMapView = isMapView && isSmallScreen;
  const showMapButton =
    isSmallScreen && !isMapView && listingResults?.total > 0;
  // This FF can be used to enable quick performance optimisation (by disabling map search) in case of a surge in user traffic on our website
  const isAutomaticSearchDisabled = isFeatureFlagEnabled(
    MAP_SEARCH_NO_AUTOMATIC_UPDATE,
  );

  // We use a key for HOMES_FOR_UKRAINIANS category
  let mapTilerKey;
  if (hasCategory && category?.label === CATEGORY_HOMES_FOR_UKRAINIANS) {
    mapTilerKey = WF_CONFIG.MAP_TILER_UKRAINIAN_KEY;
  }
  // End map related

  const selectedListing = propsQuery.listing || "";
  const listingViewModel = createListingViewModel(t, propsParamsLang);

  const suppressNotFoundError = true;
  const regionName = t(
    `regions.${propsParamsRegion}`,
    undefined,
    suppressNotFoundError,
  );
  const capitalizedRegionSlug =
    regionName || safeDecodeComponent(propsParamsRegion);

  const metaTitle =
    regionDistricts?.length > 0 &&
    keyExists(`pages.ListingsPage.documentTitle.${propsParamsRegion}`)
      ? // eslint-disable-next-line local/only-existing-locales
        t(`pages.ListingsPage.documentTitle.${propsParamsRegion}`)
      : t("pages.ListingsPage.documentTitle", {
          region: capitalizedRegionSlug,
        });

  const regionSlug = propsParamsRegion;
  const content = generateHeadContent({
    t,
    capitalizedRegionSlug,
    pageDataContent,
    metaTitle,
  });

  const headProps = getHeadProps({
    listings: listingResults.items || [],
    total: listingResults.total,
    itemsPerPage: listingResults.itemsPerPage,
    query: propsQuery,
    capitalizedRegionSlug,
    lowPrice: pageDataStructured.lowPrice,
    highPrice: pageDataStructured.highPrice,
    lang,
    t,
    content,
    category,
    paramsPage: propsParamsPage,
    region: propsParamsRegion,
    translationUrls,
    hasCategory,
  });

  const page = propsParamsPage || "1";
  const previousPage = usePreviousValue(page);
  const regions = useMemo(
    () => sanitizeRegions({ regions: pageDataRegions, t }),
    [pageDataRegions, t],
  );

  const {
    items = [],
    total,
    itemsPerPage,
  } = useMemo(() => listingResults, [listingResults]);

  const filterCity = useMemo(
    () => safeDecodeComponent(defaultFilters?.city),
    [defaultFilters?.city],
  );

  const regionCity = useMemo(
    () =>
      regions.some(({ slug }) => slug === propsParamsRegion) &&
      propsParamsRegion,
    [regions, propsParamsRegion],
  );

  const city = useMemo(
    () => regionCity || filterCity || DEFAULT_CITY,
    [regionCity, filterCity],
  );

  const analyticsEvent = useMemo(
    () => (canUseDOM() ? analytics.category("ListingSearchResults") : () => {}),
    [analytics],
  );

  const saveSearchMetaData = (groupId, positionOnPage) => {
    store.set(`SearchMetaData-${groupId}`, {
      totalSearchResults: listingResults?.total,
      // This is undefined for the first page.
      listingWasOnPage: Number.parseInt(propsParamsPage ?? 1, 10),
      positionOnPage,
      filters: JSON.stringify({
        ...propsQuery,
        region: propsParamsRegion,
      }),
    });
  };

  const categorySlug = category?.slug;
  const getNextURL = useCallback(
    ({ params, query, clearFilters = false }) => {
      const newParams = {
        page: propsParamsPage,
        region: propsParamsRegion,
        lang: propsParamsLang,
        category: categorySlug,
        ...params,
      };

      // If clearFilters is true, ignore propsQuery and use only the new query
      let newQuery = clearFilters
        ? { ...query }
        : Object.entries({ ...propsQuery, ...query }).reduce(
            (acc, [key, value]) => {
              if (value !== null && value !== undefined) {
                acc[key] = value;
              }
              return acc;
            },
            {},
          );

      // We don't want to expose `labels` on URL since it's unnecessary.
      newQuery = omit(newQuery, ["labels"]);

      if (categorySlug) {
        return `${url("categories", newParams)}?${qs.stringify(newQuery)}`;
      }
      return `${url("listinglist", newParams)}?${qs.stringify(newQuery)}`;
    },
    [
      categorySlug,
      propsParamsPage,
      propsParamsLang,
      propsParamsRegion,
      propsQuery,
      url,
    ],
  );

  const updateURL = useCallback(
    ({ params, query, clearFilters }) => {
      const nextURL = getNextURL({ params, query, clearFilters });

      history.push(nextURL);
    },
    [history, getNextURL],
  );

  const onChangeFilter = useCallback(
    (query, { clearFilters = false } = {}) => {
      if (clearFilters) {
        query = pick(query, ["search", "bbox"]);
      } else {
        Object.keys(query).forEach((filterName) => {
          const value = query[filterName];

          defaultListingFilter.set(
            filterName,
            filterName === "minAccommodates" ? Number(value) : value,
          );

          triggerGAEvent(filterName, value, analyticsEvent);
        });

        query.maxPriceTemp = null;
        query.minPriceTemp = null;
      }
      const updatedQuery = updateQuery(propsQuery, query, { clearFilters });

      const trackingFilters = {
        ...updatedQuery,
        categoryLabel: category?.label,
      };

      if (!trackingFilters.from) {
        trackingFilters.from = dayjs.utc().format("YYYY-MM-DD");
      }

      tracker.events.trackSearchFilterOnSrp({
        userId: user?._id || null,
        location: decodeURIComponent(propsParamsRegion),
        filters: trackingFilters,
      });

      updateURL({
        params: { page: null },
        query: updatedQuery,
        clearFilters,
      });
    },
    [
      analyticsEvent,
      category,
      user,
      tracker.events,
      propsQuery,
      updateURL,
      propsParamsRegion,
    ],
  );

  const onSearch = useCallback(
    (selectedSuggestion) => {
      const searchTextEn = getGeocodingItemEnglishLabel(selectedSuggestion);
      analyticsEvent(
        hasCategory ? "categories_new_search" : "srp_new_search",
        searchTextEn,
      );

      const searchText = getGeocodingItemLabel(selectedSuggestion);
      defaultListingFilter.set("region", encodeURIComponent(searchText));
      defaultListingFilter.set(
        "city",
        encodeURIComponent(selectedSuggestion.region),
      );
      defaultListingFilter.set("search", selectedSuggestion.id);

      const { bbox, ...query } = stateQuery;
      const newQuery = { ...query, search: selectedSuggestion.id, bbox: null };

      setStateQuery(newQuery);
      // Updating the query will trigger a map move which will trigger a query update (infinite loop).
      // We set it to true to prevent the infinite loop.
      setMapMovingBecauseOfSearch(true);

      updateURL({
        params: {
          lang: propsParamsLang,
          page: null,
          region: searchText || propsParamsRegion,
        },
        query: newQuery,
      });

      tracker.events.trackLocationChangeOnSrp({
        userId: user?._id || null,
        location: decodeURIComponent(propsParamsRegion),
      });
    },
    [
      tracker,
      user,
      analyticsEvent,
      propsParamsLang,
      propsParamsRegion,
      stateQuery,
      updateURL,
      hasCategory,
    ],
  );

  const redirectBackToList = () => {
    const { listing, ...query } = propsQuery;
    updateURL({ query: { ...query, view: null } });
  };

  const switchToListView = () => {
    analyticsEvent("search_click_list_view");
    updateURL({ query: { view: null } });
  };

  // Start Map
  const switchToMapView = () => {
    analyticsEvent("search_click_map_view");

    if (shouldReloadMap) {
      setListingsMapKey(getRandomKey());
      setShouldReloadMap(false);
    }

    updateURL({ query: { view: "map" } });
  };

  const updateBboxURL = ({ params, query }) => {
    const nextUrl = `${url(routeName, params)}?${qs.stringify(query)}`;
    history.replace(nextUrl);
  };

  const updateMapListings = (bounds = mapBounds) => {
    // We want to have search or bbox, not both (search NAND bbox)
    const { listing, search, ...query } = propsQuery;
    const bbox = getEncodedBboxStringFromLatLngArray(bounds);
    const newQuery = { ...query, bbox };
    setStateQuery(newQuery);
    /** We don't pass the page parameter to this update because we want to return
     * to page one when we conduct a new search though map move.
     */
    updateBboxURL({
      params: {
        lang: propsParamsLang,
        region: propsParamsRegion,
        category: category?.slug,
      },
      query: newQuery,
    });
  };

  const onMapMove = (bounds, searchWhenMoving) => {
    if (
      !isSmallScreen &&
      searchWhenMoving &&
      !mapMovingBecauseOfSearch &&
      !isAutomaticSearchDisabled
    ) {
      updateMapListings(bounds);
    }

    tracker.events.trackMapBboxChange({
      userId: user?._id || null,
      location: decodeURIComponent(propsParamsRegion),
      bbox: [bounds[0].lng, bounds[0].lat, bounds[1].lng, bounds[1].lat],
    });

    setMapBounds(bounds);
    setMapMovingBecauseOfSearch(false);
  };

  const handleListingClickOnMap = (groupId, positionOnPage) => {
    trackListingClickEvent({
      tracker,
      listing: { groupId },
      itemIndexOnPage: null,
      clickPlacement: listingsSearchResultClickPlacement.MAP,
    });

    saveSearchMetaData(groupId, positionOnPage);
  };
  // End Map

  // Start Snowplow tracking for listing results
  const extractGroupIds = useCallback((listingResults) => {
    return listingResults.items?.map((item) => item.groupId);
  }, []);

  useEffect(() => {
    // do not trigger events when on Helfende Wande Collection with the modal being displayed
    if (propsQuery.showPlaceOfResidenceModal) return;

    // trigger only when page changes; skips first render
    if (page !== previousPage) {
      tracker.events.trackSearchResultsPageChange({
        userId: user?._id || null,
        searchResultsPage: Number.parseInt(page, 10),
      });
    }

    tracker.events.trackSearchResultsDisplayed({
      userId: user?._id || null,
      listingIds: extractGroupIds(listingResults),
    });
  }, [
    tracker,
    user,
    propsQuery,
    page,
    listingResults,
    extractGroupIds,
    previousPage,
  ]);

  // End Snowplow tracking

  // Start dataLayer (used for Google Tag Manager)
  const isFirstUpdate = useRef(true);
  const previousDataLayerKeyProp = usePreviousValue(dataLayerKey);
  const hasDataLayerKeyChanged = previousDataLayerKeyProp !== dataLayerKey;

  useEffect(() => {
    // Data layer should be persisted on component mount and if dataLayerKey prop changes
    const shouldPersistDataLayer =
      isFirstUpdate.current || hasDataLayerKeyChanged;

    if (shouldPersistDataLayer) {
      persistDataLayerVars({
        listingResults,
        dataLayerKey,
        user,
        query: propsQuery,
        regionSlug: propsParamsRegion,
      });

      if (isFirstUpdate.current) {
        isFirstUpdate.current = false;
      }
    }
  }, [
    dataLayerKey,
    listingResults,
    user,
    propsQuery,
    propsParamsRegion,
    hasDataLayerKeyChanged,
  ]);
  // End dataLayer

  // images for category header
  let headerImgSrcSet;
  let headerImgSrcSetMobile;

  // Start Refugee Distribution
  // TODO DEV-10309 remove feature flag
  const isRefugeeDistributionFlagOn = isFeatureFlagEnabled(
    ENVIRONMENT_REFUGEE_DISTRIBUTION,
  );
  const showRefugeeDistributionFeatures =
    isRefugeeDistributionFlagOn &&
    hasCategory &&
    category?.label === CATEGORY_HOMES_FOR_UKRAINIANS;

  if (hasCategory) {
    headerImgSrcSet = generateSrcSet({
      images: category.heroImageLandscape,
    });

    headerImgSrcSetMobile = showRefugeeDistributionFeatures
      ? HELFENDE_WAENDE_IMAGE
      : generateSrcSet({
          images: category.heroImagePortrait,
        });
  }

  const showHeaderWithBanner = !isMobileMapView && hasCategory;
  const showHeader = !isMobileMapView && !showHeaderWithBanner;

  const applyRefugeeTeritorialSearch = ({
    federalState,
    municipality,
    bbox,
  }) => {
    updateURL({
      query: {
        federalState,
        municipality,
        showPlaceOfResidenceModal: null,
        bbox,
      },
    });
  };

  /** Search location text is only shown in the Ukraine category. It isn't displayed when the refugeeDistributionModal is open. */
  const refugeeSearchLocationText =
    !showRefugeeDistributionFeatures || propsQuery.showPlaceOfResidenceModal
      ? ""
      : getRefugeeSearchLocationText({
          t,
          federalState: propsQuery.federalState
            ? misc.statesOfGermany.STATES_OF_GERMANY_OPTIONS.find(
                (state) => state.value === propsQuery.federalState,
              )?.text
            : null,
          municipality: currentMunicipality?.name,
        });

  const formattedSearchLocationText = propsQuery.federalState ? (
    <React.Fragment>
      {t(
        "pages.ListingsPage.header.refugeeSearchArea.refugeeSearchLocationText",
      )}{" "}
      <b>{refugeeSearchLocationText}</b>
    </React.Fragment>
  ) : (
    refugeeSearchLocationText
  );

  const isRefugeeDistributionModalVisible =
    propsQuery.showPlaceOfResidenceModal && showRefugeeDistributionFeatures;
  const showChangeLocationButton =
    showRefugeeDistributionFeatures && !isRefugeeDistributionModalVisible;

  const categoriesWithoutUkraineCategory = categories
    ? categories?.filter(
        (category) => category?.label !== CATEGORY_HOMES_FOR_UKRAINIANS,
      )
    : [];
  // End Refugee Distribution

  return (
    <HoveredItemContextProvider initialHoveredItem={propsQuery.listing}>
      <div
        className={classnames("ListingsPage", {
          hasHeaderWithBanner: hasCategory,
          "ListingsPage-outerSmallScreen": isSmallScreen,
        })}
      >
        <PageHead {...headProps} />
        {isMobileMapView && (
          <Button
            className="ListButton"
            colorVariant="white"
            onClick={redirectBackToList}
          >
            <img
              src="/public/images/listings-page/arrow-right.svg"
              className="ButtonImage"
              alt="" // parent Button component has text so this image is purely decorative and doesn't need a description
            />
            {t("backToList")}
          </Button>
        )}
        {showHeader && (
          <Header
            lang={lang}
            t={t}
            user={user}
            query={propsQuery}
            regionSlug={regionSlug}
            capitalizedRegionSlug={capitalizedRegionSlug}
            onChangeRegion={onSearch}
            isSmallScreen={isSmallScreen}
            onChangeFilter={onChangeFilter}
            isMapView={isMapView}
            switchToMapView={switchToMapView}
            switchToListView={switchToListView}
            autoHide={isSmallScreen}
            updateURL={updateURL}
            logoProps={{
              showOnlyBadge: isSmallScreen,
            }}
            currentUrl={currentUrl}
            analyticsEvent={analyticsEvent}
            translationUrls={translationUrls}
            searchBias={searchBiasBbox}
            regionBbox={regionBbox}
            url={url}
            minBookingDuration={minBookingDuration}
          />
        )}
        {showHeaderWithBanner && (
          <HeaderWithBanner
            lang={lang}
            t={t}
            user={user}
            query={{
              ...propsQuery,
              labels: category.label,
            }}
            regionSlug={regionSlug}
            capitalizedRegionSlug={capitalizedRegionSlug}
            isSmallScreen={isSmallScreen}
            onChangeRegion={onSearch}
            onChangeFilter={onChangeFilter}
            isMapView={isMapView}
            switchToMapView={switchToMapView}
            switchToListView={switchToListView}
            autoHide={isSmallScreen}
            logoProps={{
              showOnlyBadge: isSmallScreen,
            }}
            currentUrl={currentUrl}
            url={url}
            regionBbox={regionBbox}
            updateURL={updateURL}
            translationUrls={translationUrls}
            categoryTitle={category.title}
            minBookingDuration={minBookingDuration}
            subText={
              <React.Fragment>
                <p
                  className={
                    showRefugeeDistributionFeatures
                      ? "ListingsPage-HeaderWithBanner-description--refugees"
                      : ""
                  }
                >
                  {category.description}
                </p>
                {refugeeSearchLocationText && (
                  <p
                    className="ListingsPage-HeaderWithBanner-searchLocation"
                    data-testid="HeaderWithBanner-searchLocation"
                  >
                    {formattedSearchLocationText}
                  </p>
                )}
                {showRefugeeDistributionFeatures && (
                  <div className="ListingsPage-HeaderWithBanner-refugeeDistributionFeaturesContainer">
                    {showChangeLocationButton && (
                      <div className="ListingsPage-HeaderWithBanner-changeLocationButton">
                        <Button
                          onClick={() =>
                            updateURL({
                              query: {
                                showPlaceOfResidenceModal: true,
                              },
                            })
                          }
                          colorVariant="secondaryBlue"
                          dataTestId="ListingsPage-HeaderWithBanner-changeLocationButton"
                        >
                          {t(
                            "pages.ListingsPage.header.refugeeSearchArea.changeLocation",
                          )}
                        </Button>
                      </div>
                    )}

                    <div
                      className={classnames("HeaderWithBanner-banner-logos", {
                        withChangeLocationButton: showChangeLocationButton,
                      })}
                    >
                      <img
                        src={getHelfendeWaendeLogo(lang)}
                        alt="In Association With HelfendeWaende"
                      />
                      <img
                        className="cofundedByTheEu-Logo"
                        src={getCofundedByTheEULogo(lang)}
                        alt="Co-funded by the European Union"
                      />
                    </div>
                  </div>
                )}
              </React.Fragment>
            }
            fontFamily={category.titleFontFamily || undefined}
            analyticsEvent={analyticsEvent}
            searchBias={searchBiasBbox}
            showSearchBox={!showRefugeeDistributionFeatures}
            showLandlordLinks={!showRefugeeDistributionFeatures}
            showListingsBar={!isRefugeeDistributionModalVisible}
            isRefugeeDistributionModalVisible={
              isRefugeeDistributionModalVisible
            }
            bannerPicture={
              <HeaderImage
                srcSet={headerImgSrcSet}
                alt={
                  category.heroImageLandscape.length
                    ? category.heroImageLandscape[0].alt
                    : null
                }
                mobileSrcSet={headerImgSrcSetMobile}
                src={
                  category.heroImageLandscape.length
                    ? category.heroImageLandscape[0].url
                    : null
                }
              />
            }
          />
        )}

        {isRefugeeDistributionModalVisible && (
          <RefugeeDistributionModal
            municipality={propsQuery?.municipality}
            federalState={propsQuery?.federalState}
            applyRefugeeTeritorialSearch={applyRefugeeTeritorialSearch}
          />
        )}
        {!isRefugeeDistributionModalVisible && (
          <div
            className={classnames("Listings", {
              "is-mapview": isMapView,
              "is-empty": items.length === 0,
            })}
          >
            {!isMapView && (
              <article className="Listings-wrapper">
                <DatesInfoBanner
                  t={t}
                  onChangeFilter={onChangeFilter}
                  query={propsQuery}
                  region={city}
                  setCookieValue={defaultListingFilter.set}
                  minBookingDuration={minBookingDuration}
                />
                {hasCategory && !showRefugeeDistributionFeatures && (
                  <BreadcrumbsNav
                    category={category}
                    /** TODO DEV-10309 Remove feature flag and always pass categoriesWithoutUkraineCategory */
                    categories={
                      isRefugeeDistributionFlagOn
                        ? categoriesWithoutUkraineCategory
                        : categories
                    }
                    lang={lang}
                    region={regionSlug}
                    cityName={capitalizedRegionSlug}
                    query={omit(propsQuery, ["labels"])}
                    analyticsEvent={analyticsEvent}
                    analyticsEventData={{
                      action: "search_click_collections_menu",
                    }}
                    url={url}
                  />
                )}
                {/** This div is here to ensure MapButton can't overlap with city description and pagination */}
                <div
                  className={
                    showMapButton ? "Listings-mapButtonScrollWrapper" : ""
                  }
                >
                  {showMapButton && (
                    <Button
                      size="medium"
                      className="MapButton"
                      colorVariant="white"
                      onClick={switchToMapView}
                      dataTestId="Mobile-mapButton"
                    >
                      <img
                        src="/public/images/listings-page/map-marker.svg"
                        className="ButtonImage"
                      />
                      {t("map")}
                    </Button>
                  )}
                  {items.length !== 0 && (
                    <ListingsList
                      categories={categories}
                      city={city}
                      totalItems={total}
                      title={content.title}
                      items={items}
                      listingCardTrackingCallback={(listing) => {
                        const position =
                          items.findIndex(
                            (l) => l.groupId === listing.groupId,
                          ) + 1;
                        api.analytics.createListingViewedEvent(
                          listing.groupId,
                          "SEARCH_RESULTS_LIST",
                          "",
                          {
                            page: Number(page),
                            position,
                          },
                        );
                      }}
                      onClickListing={(listing, index) => {
                        trackListingClickEvent({
                          listingResultsTotal: listingResults.total,
                          listingResultsItemsPerPage:
                            listingResults.itemsPerPage,
                          paramPage: propsParamsPage,
                          tracker,
                          listing,
                          itemIndexOnPage: index,
                          clickPlacement:
                            listingsSearchResultClickPlacement.LIST,
                        });

                        tracker.events.trackListingOnSearchResultsPageClicked({
                          userId: user?._id || null,
                          groupId: listing.groupId,
                          position: index + 1,
                          pageNumber: Number.parseInt(page, 10),
                        });

                        store.set(INTERNAL_REFERRER_SESSION_STORAGE_KEY, {
                          r: "SRP",
                        });
                        analyticsEvent(
                          "go to listing details from list",
                          listing.groupId,
                        );
                        analyticsEvent(
                          "search_results_general_click",
                          listing.groupId,
                        );
                        saveSearchMetaData(listing.groupId, index + 1);
                      }}
                      onSelectImage={() => {
                        analyticsEvent("scroll image gallery on list");
                      }}
                      url={url}
                      query={propsQuery}
                      dataLayerKey={dataLayerKey}
                      isSmallScreen={isSmallScreen}
                    />
                  )}
                </div>
                {items.length === 0 && (
                  <EmptyStateMessage
                    t={t}
                    url={url}
                    lang={lang}
                    regionSlug={regionSlug}
                    query={propsQuery}
                    hasCategory={hasCategory}
                    showRefugeeDistributionFeatures={
                      showRefugeeDistributionFeatures
                    }
                  />
                )}

                {total != null && (
                  <div
                    className={
                      isSmallScreen
                        ? "Pagination-wrapper--mobile"
                        : "Pagination-wrapper"
                    }
                  >
                    <Pagination
                      currentPage={page ? Number(page) : 1}
                      getUrl={(page) =>
                        getNextURL({
                          params: {
                            page: page === 1 ? undefined : page,
                          },
                        })
                      }
                      total={total}
                      itemsPerPage={itemsPerPage}
                      isMobile={isSmallScreen}
                    />
                  </div>
                )}
                {shouldShowCityDescription({ content, page }) ? (
                  <article className="CityDescription-wrapper">
                    <CityDescription data={content.h2s} region={regionSlug} />
                  </article>
                ) : null}
              </article>
            )}
            {(!isSmallScreen || (isSmallScreen && isMapView)) &&
              regions.length && (
                <MapErrorBoundary t={t}>
                  <ListingsMap
                    key={listingsMapKey}
                    setMapRef={() => {}}
                    bounds={getBboxArrayFromString(
                      propsQuery.bbox || searchedPlaceBbox,
                    )}
                    mapMovingBecauseOfSearch={mapMovingBecauseOfSearch}
                    listings={items}
                    query={propsQuery}
                    dataLayerKey={dataLayerKey}
                    onMapMove={onMapMove}
                    getListingTitle={(listing) =>
                      listingViewModel.title(listing)
                    }
                    getListingUrl={(listing) => {
                      const finalQuery = { ...propsQuery, dataLayerKey };

                      return (
                        listingViewModel.permalinkUrl(listing) +
                        (Object.keys(finalQuery).length
                          ? `?${qs.stringify(finalQuery)}`
                          : "")
                      );
                    }}
                    event={analyticsEvent}
                    numTotalResults={total || 0}
                    numResults={items.length}
                    searchThisArea={updateMapListings}
                    isMapView={isMapView}
                    isSmallScreen={isSmallScreen}
                    updateUrl={updateURL}
                    selectedListing={selectedListing}
                    onClickListing={handleListingClickOnMap}
                    mapTilerKey={mapTilerKey}
                    showMapTilerLogo={!!mapTilerKey}
                    isAutomaticSearchDisabled={isAutomaticSearchDisabled}
                    category={category}
                  />
                </MapErrorBoundary>
              )}
          </div>
        )}
        {!isMapView && (
          <Footer
            url={url}
            user={user}
            className="unfix"
            regions={pageDataRegions}
            districts={regionDistricts}
            showRefugeeDistributionFeatures={showRefugeeDistributionFeatures}
          />
        )}
      </div>
    </HoveredItemContextProvider>
  );
}

const propTypes = {
  translationUrls: PropTypes.object.isRequired,
  user: PropTypes.object,
  query: PropTypes.object,
  pageData: PropTypes.shape({
    dataLayerKey: PropTypes.string.isRequired,
    regions: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
      }),
    ).isRequired,
    listingResults: PropTypes.any,
    structuredData: PropTypes.shape({
      lowPrice: PropTypes.number,
      highPrice: PropTypes.number,
    }).isRequired,
    category: PropTypes.object,
    categories: PropTypes.arrayOf(PropTypes.object),
    content: PropTypes.object,
    searchBiasBbox: PropTypes.string,
    searchedPlaceBbox: PropTypes.string,
    defaultFilters: PropTypes.object,
    minBookingDuration: PropTypes.number,
    regionDistricts: PropTypes.arrayOf(PropTypes.object),
    currentMunicipality: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    regionBbox: PropTypes.string,
  }).isRequired,
  params: PropTypes.shape({
    lang: PropTypes.string.isRequired,
    region: PropTypes.string.isRequired,
    page: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
  }).isRequired,
  currentUrl: PropTypes.string.isRequired,
  url: PropTypes.func.isRequired,
  isAPhone: PropTypes.bool,
};
ListingsPage.propTypes = propTypes;
export default ListingsPage;
