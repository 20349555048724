import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import AppHeader from "../../../../../components/AppHeader/AppHeader";
import GeocodingSearch from "../../../../../components/GeocodingSearch/GeocodingSearch";
import GoogleFont from "../../../../../components/GoogleFont/GoogleFont";
import debounce from "../../../../../components/StickyHeader/debounce";
import ListingFilter from "../ListingFilter/ListingFilter";
import "./HeaderWithBanner.scss";

const HeaderWithBanner = ({
  translationUrls,
  bannerPicture,
  categoryTitle,
  currentUrl,
  fontFamily = "Bangers",
  isMapView,
  isSmallScreen,
  lang,
  logoProps,
  onChangeFilter,
  onChangeRegion,
  query,
  regionSlug,
  subText,
  t,
  updateURL,
  switchToListView,
  switchToMapView,
  user,
  analyticsEvent,
  searchBias,
  capitalizedRegionSlug,
  showSearchBox = true,
  showLandlordLinks = true,
  showListingsBar = true,
  isRefugeeDistributionModalVisible,
  url,
  regionBbox,
  minBookingDuration,
}) => {
  const region = {
    label: capitalizedRegionSlug,
    value: regionSlug,
  };

  const [isGoogleFontLoaded, setIsGoogleFontLoaded] = React.useState(false);
  const coverImageRef = React.useRef(null);
  const appHeaderRef = React.useRef(null);
  const listingsFiltersRef = React.useRef(null);

  React.useEffect(() => {
    // If the refugee distribution modal is visible, we don't want the header to disappear and reappear on scroll.
    if (isRefugeeDistributionModalVisible) {
      return;
    }

    let lastKnownScrollPosition = 0;
    /** When the orientation of a device changes, the scroll position should be reset because the
     * lastKnownScrollPosition is not correct anymore (it doesn't match the height of the device with new orientation).
     */
    let resetScrollPosition = false;

    /**
     * As we are scrolling down, we want the app header background to go from
     * transparent to full white, and the text to go from white to dark gray.
     * We also need to hide/show the header & filter bar on mobile depending on the scroll direction.
     */
    const onScroll = debounce(() => {
      const scrollPosition = window.scrollY;
      if (resetScrollPosition) {
        lastKnownScrollPosition = scrollPosition;
        resetScrollPosition = false;
      }
      const headerHeight = appHeaderRef.current.getBoundingClientRect().height;
      const bannerHeight = coverImageRef.current.getBoundingClientRect().height;
      const fullColorStop = bannerHeight * 0.75;
      const scrollingDown = scrollPosition > lastKnownScrollPosition;

      let bgOpacity = 0;
      if (scrollPosition <= fullColorStop / 2 - headerHeight) {
        bgOpacity = 0;
      } else if (scrollPosition + headerHeight >= fullColorStop) {
        bgOpacity = 1;
      } else {
        bgOpacity = ((scrollPosition + headerHeight) / fullColorStop).toFixed(
          2,
        );
      }

      if (bgOpacity > 0) {
        appHeaderRef.current.classList.remove("AppHeader-theme--image");
        appHeaderRef.current.classList.add("AppHeader-theme--white");
      } else {
        appHeaderRef.current.classList.remove("AppHeader-theme--white");
        appHeaderRef.current.classList.add("AppHeader-theme--image");
      }

      appHeaderRef.current.style.setProperty("--bgColorOpacity", bgOpacity);

      if (scrollPosition >= bannerHeight + headerHeight) {
        appHeaderRef.current.classList.toggle("mobile--hidden", scrollingDown);
        listingsFiltersRef.current?.classList.toggle(
          "mobile--hidden",
          scrollingDown,
        );
      }
      lastKnownScrollPosition = scrollPosition;
    });

    const onOrientationChange = () => {
      resetScrollPosition = true;
      onScroll();
    };

    onScroll();
    window.addEventListener("scroll", onScroll);
    if (screen && screen.orientation) {
      screen.orientation.addEventListener("change", onOrientationChange);
    } else {
      // For Safari versions 16.3 and older. We can delete this once we no longer need to support these versions.
      window.addEventListener("orientationchange", onOrientationChange);
    }

    return () => {
      window.removeEventListener("scroll", onScroll);
      if (screen && screen.orientation) {
        screen.orientation.removeEventListener("change", onOrientationChange);
      } else {
        window.removeEventListener("orientationchange", onOrientationChange);
      }
    };
  }, [isRefugeeDistributionModalVisible]);

  return (
    <Fragment>
      <AppHeader
        theme="image"
        translationUrls={translationUrls}
        lang={lang}
        t={t}
        user={user}
        logoProps={logoProps}
        currentUrl={currentUrl}
        hasNoShadow
        className={classNames(
          "AppHeader--withBanner",
          isRefugeeDistributionModalVisible && "AppHeader--withBanner--static",
        )}
        ref={appHeaderRef}
        analyticsEvent={analyticsEvent}
        showLandlordLinks={showLandlordLinks}
      >
        {showSearchBox && (
          <GeocodingSearch
            lang={lang}
            bias={searchBias}
            isTransparent
            value={region.label}
            onSelectSuggestion={onChangeRegion}
            placeholder={t("components.GeocodingInputSearch.placeholder")}
          />
        )}
      </AppHeader>
      <figure
        ref={coverImageRef}
        className={classNames(
          "HeaderWithBanner-banner",
          isRefugeeDistributionModalVisible && "RefugeeDistributionModalOpen",
        )}
      >
        {bannerPicture}
        <figcaption
          className={classNames(
            "HeaderWithBanner-banner-content",
            isGoogleFontLoaded && "HeaderWithBanner-banner-content--loaded",
            isRefugeeDistributionModalVisible &&
              "HeaderWithBanner-banner-content--withLogo",
          )}
        >
          <h2>
            <GoogleFont
              className="HeaderWithBanner-banner-categoryTitle"
              fontName={fontFamily}
              onLoad={() => {
                setIsGoogleFontLoaded(true);
              }}
            >
              {categoryTitle}
            </GoogleFont>
          </h2>
          <div className="HeaderWithBanner-banner-subText">{subText}</div>
        </figcaption>
      </figure>
      {showListingsBar && (
        <div
          ref={listingsFiltersRef}
          className={classNames("Listings-bar Listings-bar--withBanner", {
            "is-mapview": isMapView,
          })}
          data-testid="listings-bar"
        >
          <ListingFilter
            t={t}
            lang={lang}
            isSmallScreen={isSmallScreen}
            query={query}
            regionSlug={regionSlug}
            onChange={onChangeFilter}
            updateURL={updateURL}
            analyticsEvent={analyticsEvent}
            url={url}
            currentUrl={currentUrl}
            regionBbox={regionBbox}
            isUserLoggedIn={!!user}
            minBookingDuration={minBookingDuration}
          />

          {!isSmallScreen && (
            <span className="Listings-viewSelector">
              <button
                className={classNames(
                  "Listings-viewListButton",
                  !isMapView && "Listings-viewListButton--active",
                )}
                onClick={switchToListView}
              >
                {t("list")}
              </button>
              <button
                className={classNames(
                  "Listings-viewMapButton",
                  isMapView && "Listings-viewMapButton--active",
                )}
                data-testid="map-view-button"
                onClick={switchToMapView}
              >
                {t("map")}
              </button>
            </span>
          )}
        </div>
      )}
    </Fragment>
  );
};

HeaderWithBanner.propTypes = {
  t: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  user: PropTypes.object,
  regionSlug: PropTypes.string.isRequired,
  onChangeRegion: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func,
  isSmallScreen: PropTypes.bool,
  query: PropTypes.object,
  isMapView: PropTypes.bool.isRequired,
  switchToMapView: PropTypes.func.isRequired,
  switchToListView: PropTypes.func.isRequired,
  updateURL: PropTypes.func.isRequired,
  logoProps: PropTypes.object,
  currentUrl: PropTypes.string,
  bannerPicture: PropTypes.node.isRequired,
  categoryTitle: PropTypes.node,
  subText: PropTypes.node,
  fontFamily: PropTypes.string,
  analyticsEvent: PropTypes.func.isRequired,
  translationUrls: PropTypes.object.isRequired,
  searchBias: PropTypes.string,
  capitalizedRegionSlug: PropTypes.string,
  showSearchBox: PropTypes.bool,
  showLandlordLinks: PropTypes.bool,
  showListingsBar: PropTypes.bool,
  isRefugeeDistributionModalVisible: PropTypes.bool,
  url: PropTypes.func.isRequired,
  regionBbox: PropTypes.string,
  minBookingDuration: PropTypes.number,
};

export default HeaderWithBanner;
